/* ButtonSwitcher Solution Page */
.label {
    color: rgba(47, 43, 67, 0.6);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 241px;
    border-radius: 100px;
  }
  
  .switch {
    height: 44px;
    width: 241px;
    color: black !important;
    background-color: white;
    border-radius: 100px;
    position: absolute;
    left: 3px;
    top: 3px;
    transition: ease-in;
  }
  
  .label_checked {
      color:#2F2B43;
  }
  /*---------------- LEFT ------------------- */
  .switch.first_to_second {
    animation-name: firstTosecond;
    animation-duration: 0.5s;
  }
  
  .switch.first_to_third {
    animation-name: firstTothird;
    animation-duration: 0.5s;
  }
  .switch.first_to_fourth {
    animation-name: firstTofourth;
    animation-duration: 0.5s;
  }
  
  .switch.first_position {
    left: 3px;
  }
  
  .first_label {
    position: absolute;
  }
  
  @keyframes firstTosecond {
    from {
      left: 3px;
    }
  
    to {
      left: 246px;
    }
  }
  
  @keyframes firstTothird {
    from {
      left: 3px;
    }
  
    to {
      left: 478px;
    }
  }
  @keyframes firstTofourth{
    from {
      left: 3px;
    }
  
    to {
      left: 710px;
    }
  }
  /* -------------------- second ------------------ */
  .switch.second_to_first {
    animation-name: secondTofirst;
    animation-duration: 0.5s;
  }
  
  .switch.second_to_third {
    animation-name: secondTothird;
    animation-duration: 0.5s;
  }
  
  .switch.second_to_fourth {
    animation-name: secondTofourth;
    animation-duration: 0.5s;
  }
  
  .switch.second_position {
    left: 246px;
  }
  
  .second_label {
    position: absolute;
    left: 246px;
  }
  
  
  
  @keyframes secondTofirst {
    from {
      left: 246px;
    }
  
    to {
      left: 3px;
    }
  }
  
  @keyframes secondTofourth {
    from {
      left: 246px;
    }
  
    to {
      right: 1px;
    }
  }
  @keyframes secondTothird {
    from {
      left: 246px;
    }
  
    to {
      left: 478px;
    }
  }
  
  /* ------------------- RIGHT ------------------- */
  .switch.third_to_first {
    animation-name: thirdTofirst;
    animation-duration: 0.5s;
  }
  
  .switch.third_to_second {
    animation-name: thirdTosecond;
    animation-duration: 0.5s;
  }
  
  .switch.third_to_fourth{
    animation-name: thirdTofourth;
    animation-duration: 0.5s;
  }
  
  .switch.third_position {
    left: 478px;
  }
  
  .third_label {
    position: absolute;
    left: 478px;
  }
  
  
  @keyframes thirdTofirst {
    from {
      left: 328px;
    }
  
    to {
      left: 3px;
    }
  }
  
  @keyframes thirdTosecond {
    from {
      left: 328px;
    }
  
    to {
      right: 246px;
    }
  }
  @keyframes thirdTofourth {
    from {
      left: 478px;
    }
  
    to {
      right: 1px;
    }
  }
  /* ------------------- fourth ------------------- */
  .switch.fourth_to_first {
    animation-name: fourthTofirst;
    animation-duration: 0.5s;
  }
  
  .switch.fourth_to_second {
    animation-name: fourthTosecond;
    animation-duration: 0.5s;
  }
  
  .switch.fourth_to_third{
    animation-name: fourthTothird;
    animation-duration: 0.5s;
  }
  
  .switch.fourth_position {
    left: 710px;
  }
  
  .fourth_label {
    position: absolute;
    left: 710px;
  }
  
  
  @keyframes fourthTofirst {
    from {
      left: 710px;
    }
  
    to {
      left: 3px;
    }
  }
  
  @keyframes fourthTosecond {
    from {
      left: 710px;
    }
  
    to {
      right: 246px;
    }
  }
  @keyframes fourthTothird {
    from {
      left: 710px;
    }
  
    to {
      left: 478px;
    }
  }
  