@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollLock {
    overflow: hidden;
    padding-right: calc(100vw - 100%);
  }

  .comments-slider .slick-slide {
    padding: 0 12px;
  }

  .images-slider .slick-slide {
    margin-right: 24px;
  }

  .main-slider .slick-prev {
    width: 32px;
    height: 32px;
    padding-top: 3px;
  }
  .main-slider .slick-prev:focus-within {
    outline: 3px solid #c3a0ff;
    border-radius: 12px;
  }
  .main-slider .slick-prev:before {
    font-size: 32px !important;
  }

  .main-slider .slick-next {
    width: 32px;
    height: 32px;
    padding-top: 3px;
  }
  .main-slider .slick-next:focus-within {
    outline: 3px solid #c3a0ff;
    border-radius: 12px;
  }
  .main-slider .slick-next:before {
    font-size: 32px !important;
  }

  .main-slider .slick-dots li button:focus-within {
    outline: 3px solid #c3a0ff;
    border-radius: 12px;
  }

  .images-slider .slick-prev {
    left: -15px;
  }
  .images-slider .slick-next {
    right: -15px;
  }

  .main-slider .slick-arrow::before {
    color: #2f2b43;
    font-size: 25px;
  }

  .customModal {
    border-radius: 8px;
    padding: 45px !important;
    max-width: calc(100vw - 40px) !important;
  }
}

.react-responsive-modal-container {
  overflow: hidden !important;
}

.cosmith-form-select {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.tap-highlight-transparent {
  -webkit-tap-highlight-color: transparent;
}

@layer utilities {
  .focus-outline-custom {
    @apply focus:outline-cosmith-lavanda-400 focus:outline focus:outline-[3px] rounded-xl;
  }
}
